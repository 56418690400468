import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import CampaignListPage from '../pages/campaign-list/CampaignList.page';
import CampaignUploadDetailsPage from '../pages/campaign-upload-details/CampaignUploadDetails.page';
import Dashboard from '../pages/dashboard';
import CampaignUploadDetailsPageV2 from '../pages/campaign-upload-details/CampaignUploadDetailsV2.page';
import { Box, Button, Heading } from 'grommet';
import axios from 'axios';
import { showToast } from '../providers/ToastProvider';


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const RequireAuth = ({ children }: any) => {
  const isAuthenticated = useSelector((state: any) => state.user.accessToken);
  const user = useSelector((state: any) => state.user.user);
  return isAuthenticated ? <>{children}</> : <Box margin="2rem">
    <Heading> You are now logged with cognito</Heading>
    <Box height="10rem" style={{display: 'flex', justifyContent: 'space-evenly'}}>
      <Button
        primary
        label="Call lambda with token"
        onClick={() => {
          axios.get('https://kc0vxp6nm4.execute-api.us-east-2.amazonaws.com/staging/Test_cognito_auth',  {
            headers: {
              'Authorization': 'Bearer '+ user.accessToken,
            }})
            .then((response: { data: any; })=>{
              console.log(response.data);
              showToast('200 - Success', response.data, 'success');
            }).catch((error: any) => {
              console.log(error);
              showToast('Error', error.response.data, 'error');
            });
        }}
      />
      <Button
        primary
        label="Call lambda without token"
        onClick={() => {
          axios.get('https://kc0vxp6nm4.execute-api.us-east-2.amazonaws.com/staging/Test_cognito_auth',  {
            headers: {
              // 'Authorization': 'Bearer '+ user.accessToken,
            }})
            .then((response: { data: any; })=>{
              console.log(response.data);
              showToast('200 - Success', response.data, 'success');
            }).catch((error: any) => {
            showToast('Error', error.message, 'error');
          });
        }}
      />
    </Box>
  </Box>;
};

const RoutingProvider = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path='/campaigns/:campaignId'
        element={
          <RequireAuth>
            <CampaignUploadDetailsPage />
          </RequireAuth>
        }
      />
      <Route
        path='/campaigns'
        element={
          <RequireAuth>
            <CampaignListPage />
          </RequireAuth>
        }
      />
      <Route
        path='/producer/:campaignId'
        element={
          <RequireAuth>
            <CampaignUploadDetailsPageV2 />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default React.memo(RoutingProvider);
