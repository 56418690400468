import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Box, Grommet, Spinner } from 'grommet';
import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { defaultTheme } from './theme';
import store from './redux/store/index';
import { Provider as ReduxProvider } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import EnsureAuthenticated from './components/ensure-authenticated/EnsureAuthenticated.component';

const AppWithLaunchDarkly = () => {
  const LDProviderWrappedApp = withLDProvider({
    clientSideID: String(process.env.REACT_APP_LAUNCH_DARKLY_KEY),
    // user: {
    //   key: cognitoUser?.getUserAttributes('email'),
    //   email: cognitoUser?.email,
    //   name: cognitoUser?.name
    // },
  })(App);
  return <LDProviderWrappedApp />;
};

ReactDOM.render(
  <React.StrictMode>
    {/*<Auth0Provider*/}
    {/*  cacheLocation='localstorage'*/}
    {/*  scope={process.env.REACT_APP_AUTH0_SCOPE || ''}*/}
    {/*  audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}*/}
    {/*  domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}*/}
    {/*  clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}*/}
    {/*  redirectUri={window.location.origin}*/}
    {/*>*/}
      <Grommet theme={defaultTheme}>
        <ReduxProvider store={store}>
          <EnsureAuthenticated>
            <BrowserRouter>
              <AppWithLaunchDarkly />
            </BrowserRouter>
          </EnsureAuthenticated>
        </ReduxProvider>
      </Grommet>
    {/*</Auth0Provider>*/}
  </React.StrictMode>,
  document.getElementById('root'),
);
