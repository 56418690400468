import { Box, Spinner } from 'grommet';

import {
  CognitoUserPool,
  CognitoUser,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import React, { useEffect } from 'react';
import { getUserLoadedAction } from '../../redux/actions/user-actions';
import { useDispatch } from 'react-redux';
const axios = require('axios');

const poolData = {
  UserPoolId: 'us-east-2_6LzvPYsD5', // Your user pool id here
  ClientId: 'o5jqvl57645hnvvtpkpb6nb1d', // Your client id here
};
const userPool = new CognitoUserPool(poolData);

// const loader = (
//   <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
//     <div style={{ margin: '1rem auto' }}>
//       <Spinner color={'#FFE137'} size={'large'} />
//     </div>
//   </Box>
// );

const saveToken = async (response: any) => {
  // Instantiate tokens with your existing tokens
  console.log(response);
  const idToken = new CognitoIdToken({ IdToken: response.id_token });
  const accessToken = new CognitoAccessToken({
    AccessToken: response.access_token,
  });
  const refreshToken = new CognitoRefreshToken({
    RefreshToken: response.refresh_token,
  });
  console.log(idToken);

  // Create a user session
  const sessionData = {
    IdToken: idToken,
    AccessToken: accessToken,
    RefreshToken: refreshToken,
  };
  console.log(sessionData);
  const userSession = new CognitoUserSession(sessionData);
  console.log(userSession);
  const idTokenData = idToken.decodePayload();

  // Define user data
  const userData = {
    Username: idTokenData['cognito:username'], // You can extract this from the ID token if necessary
    Pool: userPool,
  };

  // Instantiate the CognitoUser object
  console.log(userData);

  const cognitoUser = new CognitoUser(userData);

  // Set the user session
  cognitoUser.setSignInUserSession(userSession);
  return validateSession(cognitoUser);
};

async function validateSession(cognitoUser: CognitoUser): Promise<any | null> {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession(
      (
        err: any,
        session: {
          isValid: () => any;
          getRefreshToken: () => CognitoRefreshToken;
        },
      ) => {
        if (err) {
          console.error('Failed to get session:', err);
          navigateToLogin();
          return;
        }
        if (session.isValid()) {
          console.log('Session is valid:', session);
          return resolve(cognitoUser);
        } else {
          console.log('Session is invalid, refreshing tokens...');
          cognitoUser.refreshSession(
            session.getRefreshToken(),
            (refreshErr, newSession) => {
              if (refreshErr) {
                console.error('Failed to refresh session:', refreshErr);
                navigateToLogin();
                return;
              }
              console.log('Session refreshed:', newSession);
              return resolve(cognitoUser);
            },
          );
        }
        return;
      },
    );
  });
}

const navigateToLogin = () => {
  console.log('to login');
  setTimeout(() => {
    window.location.href =
      'https://agoro-platform-test.auth.us-east-2.amazoncognito.com/login' +
      '?client_id=o5jqvl57645hnvvtpkpb6nb1d' +
      '&response_type=code' +
      '&scope=email+openid+phone+profile+aws.cognito.signin.user.admin' +
      '&redirect_uri=http%3A%2F%2Flocalhost:3000';
  }, 0);
};

const handleCode = async (code: string): Promise<CognitoUser | null> => {
  let url = new URL(window.location.toString());
  url.search = '';
  const newUrl = url.toString();
  window.history.replaceState({}, document.title, newUrl);
  return axios
    .post(
      'http://localhost:3003/auth/2',
      {
        code,
        redirect_uri: newUrl,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response: { data: any }) => {
      return saveToken(response.data);
    });
};

export interface EnsureAuthenticatedProps {
  children: React.ReactNode;
}

export function EnsureAuthenticated({ children }: EnsureAuthenticatedProps) {
  const [cognitoUser, setCognitoUser] = React.useState<CognitoUser | null>(
    null,
  );
  const dispatch = useDispatch();

  const loader = (
    <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
      <div style={{ margin: '1rem auto' }}>
        <Spinner color={'#FFE137'} size={'large'} />
      </div>
    </Box>
  );

  useEffect(() => {
    const userdata = (cognitoUser: CognitoUser) => {
      // const cognitoUser = userPool.getCurrentUser();
      cognitoUser.getUserData((err, data) => {
        if (err) {
          console.error('Failed to get user data:', err);
          return;
        }
        const user: Record<string, string> = {};
        for (let i = 0; i < data.UserAttributes.length; i++) {
          user[data.UserAttributes[i].Name] = data.UserAttributes[i].Value;
        }
        user.accessToken = cognitoUser
          .getSignInUserSession()
          ?.getAccessToken()
          .getJwtToken();
        dispatch(getUserLoadedAction(user));
        setCognitoUser(userPool.getCurrentUser());
      });
    };

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleCode(code).then((res) => {
        if (!!res) {
          userdata(res);
        }
      });
      return;
    }

    const currentUser = userPool.getCurrentUser();
    console.log('currentUser', currentUser);

    if (currentUser) {
      const cognitoUser = new CognitoUser({
        Username: currentUser.getUsername(), // You can extract this from the ID token if necessary
        Pool: userPool,
      });
      validateSession(cognitoUser).then((res) => {
        if (!!res) {
          userdata(res);
        }
      });
      return;
    }
    navigateToLogin();
  }, [dispatch]);
  if (!cognitoUser) {
    return loader;
  } else {
    return <div>{children}</div>;
  }
}

export default EnsureAuthenticated;
